import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import ar from "./ar.json"
import en from "./en.json"

const lang = localStorage.getItem("lang");

i18n.use(initReactI18next).init({
    resources: {
        ar: {
            translation: ar,
        },
        en: {
            translation: en,
        },
    },
    lng: lang || "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});


const I18nProvider = ({ children }) => {
    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default I18nProvider;
